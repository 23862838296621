import { detectCardType } from '../general'

// Filters
export const emailFilter = (value: string): string => {
  return value.replace(/[^0-9a-z._%@+-]/i, '').substr(0, 60)
}

export const onlyDigitsFilter = (
  value: string,
  options?: { maxLength: number },
): string => {
  return value
    .replace(/[^0-9]/, '')
    .substr(0, (options && options.maxLength) || 60)
}

export const onlyWordsFilter = (
  value: string,
  options?: { maxLength: number },
): string => {
  return value
    .replace(/[^ a-z´`^¨~á-úà-ùâ-ûä-üñ]/i, '')
    .substr(0, (options && options.maxLength) || 60)
}

export const standardStringFilter = (
  value: string,
  options?: { maxLength: number },
): string => {
  return value
    .replace(/^\s+/, '')
    .replace(/\s+$/, ' ')
    .replace(
      /[^ 0-9a-z´`^¨~á-úà-ùâ-ûä-üñºª@'".:,;!¡¿?=*\-_+<>#$%&\\/()[\]{}]/i,
      '',
    )
    .substr(0, (options && options.maxLength) || 60)
}

export const standardStringFilterWithoutSpace = (
  value: string,
  options?: { maxLength: number },
): string => {
  return value
    .replace(/^\s+/, '')
    .replace(/\s+$/, '')
    .replace(
      /[^ 0-9a-z´`^¨~á-úà-ùâ-ûä-üñºª@'".:,;!¡¿?=*\-_+<>#$%&\\/()[\]{}]/i,
      '',
    )
    .substr(0, (options && options.maxLength) || 60)
}

// Validators
export const emailValidator = (value: string): string | boolean => {
  return value === '' || /^[0-9a-z._%+-]+@[0-9a-z.-]+\.[a-z]{2,}$/i.test(value)
}

export const maxLengthValidator = (
  value: string,
  options?: { maxLength: number },
): string | boolean => {
  return value === '' || value.length <= ((options && options.maxLength) || 60)
}

export const minLengthValidator = (
  value: string,
  options?: { minLength: number },
): string | boolean => {
  return value === '' || value.length >= ((options && options.minLength) || 2)
}

export const onlyDigitsValidator = (value: string): string | boolean => {
  return value === '' || /^[0-9]+$/.test(value)
}

export const onlyWordsValidator = (value: string): string | boolean => {
  return value === '' || /^[ a-z´`^¨~á-úà-ùâ-ûä-üñ]+$/i.test(value)
}

export const requiredValidator = (
  value?: string | boolean,
): boolean | string | undefined => {
  return typeof value === 'string' ? value !== '' : value
}

export const standardStringValidator = (value: string): string | boolean => {
  return (
    value === '' ||
    /^[ 0-9a-z´`^¨~á-úà-ùâ-ûä-üñºª@'".:,;!¡¿?=*\-_+<>#$%&\\/()[\]{}]+$/i.test(
      value,
    )
  )
}

const getAge = (value: string): number => {
  const today = new Date()
  const date = value.replaceAll('-', '/')
  const birthday = new Date(date)
  let age = today.getFullYear() - birthday.getFullYear()
  const month = today.getMonth() - birthday.getMonth()
  if (month <= 0 && birthday.getDate() > today.getDate()) {
    age--
  }

  return age
}

export const legalAgeValidator = (value: string, years = 18): boolean => {
  if (value === '' || value.startsWith('0')) return false
  if (getAge(value) >= years) {
    return true
  }
  return false
}

export const validAgeValidator = (value: string, years = 100): boolean => {
  if (value === '' || value.startsWith('0')) return false
  if (getAge(value) <= years) {
    return true
  }
  return false
}

export const cardLuhnValidator = (cardNumber: number[] | string): boolean => {
  if (cardNumber === '') {
    return true
  }
  cardNumber = cardNumber
    .toString()
    .split('')
    .map((character: string) => parseInt(character, 10))
  const checkDigit = cardNumber[cardNumber.length - 1]
  const doubledCardNumber = cardNumber
    .reverse()
    .map((digit: number, i: number) => {
      const doubledNumber = i % 2 === 0 ? digit : digit * 2
      return doubledNumber > 9 ? doubledNumber - 9 : doubledNumber
    })
    .reverse()
  const summedAccountNumber = doubledCardNumber
    .slice(0, -1)
    .reduce(
      (summedNumber: number, doubledNumber: number) =>
        summedNumber + doubledNumber,
      0,
    )
  const calculatedCheckDigit = (summedAccountNumber * 9) % 10
  return calculatedCheckDigit === checkDigit
}

export const cardTypeValidator = (value: string): boolean => {
  return value === '' || detectCardType(value) !== false
}

export const validateInputValue = (
  value: string,
  validations: any[],
): string[] => {
  return validations.reduce(
    (errorMessages, { validator, options, errorMessage }) => {
      if (!validator(value, options)) {
        errorMessages.push(errorMessage)
      }
      return errorMessages
    },
    [],
  )
}

export const setDefaultAsMexPhone = (phone: string) => {
  let defaultPhone = phone.replace(/[^0-9+]/g, '')
  if (!defaultPhone.includes('+')) {
    if (defaultPhone.length <= 10) defaultPhone = `+52${defaultPhone}`
    if (defaultPhone.length === 12) defaultPhone = `+${defaultPhone}`
  }

  return defaultPhone
}
